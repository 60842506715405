@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Add this to your global CSS file */
.leaflet-control {
  z-index: 1000 !important; /* Keep map controls above the map but below modal */
}

.leaflet-draw {
  z-index: 1000 !important;
}

.leaflet-popup {
  z-index: 1000 !important;
}

/* If you're using a search component */
.search-component {
  z-index: 1001; /* Keep search above map controls */
}

/* Modal should be highest */
.modal-overlay {
  z-index: 9999;
}
